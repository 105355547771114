import type { HttpClient } from '@wix/yoshi-flow-editor';
import {
  getPostPage,
  getPostPagePreview,
} from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/http';
import {
  createAction,
  urijs,
  getCategoryIds,
  type RequestClient,
} from '@wix/communities-blog-client-common';
import type { AggregatorRequest } from '../../post-list-widget/types';
import { getLanguageCode } from '../selectors/locale-selectors';
import { getDemoPosts } from '../services/demo-posts';
import getEnvironment from '../services/get-environment';
import { normalizePost, normalizePostV3 } from '../services/post-utils';
import { encodeURISlug } from '../services/slug';
import type { AppState, NormalizedPost } from '../types';
import type { WixCodeApi } from '../types/platform-types';

export const FETCH_POST_REQUEST = 'post/FETCH_REQUEST';
export const FETCH_POST_SUCCESS = 'post/FETCH_SUCCESS';
export const FETCH_POST_FAILURE = 'post/FETCH_FAILURE';

interface FetchPostRequestAction {
  type: typeof FETCH_POST_REQUEST;
  payload: { postSlug: string };
}

interface FetchPostSuccessAction {
  type: typeof FETCH_POST_SUCCESS;
  payload: { post: NormalizedPost; postSlug: string };
}

interface FetchPostFailureAction {
  type: typeof FETCH_POST_FAILURE;
  payload: { postSlug: string; error: unknown };
}

interface PreviewPostParams {
  includeDraft?: boolean;
  instance?: string;
}

interface MakeRequestParams {
  request: RequestClient;
  includeDraft?: boolean;
  instance?: string;
}

interface MakeRequestToPlatformizedParams {
  includeDraft?: boolean;
  instance?: string;
  httpClient: HttpClient;
  languageCode: string;
}

export type PostActionTypes =
  | FetchPostRequestAction
  | FetchPostSuccessAction
  | FetchPostFailureAction;

export const fetchPostRequest = createAction(FETCH_POST_REQUEST);
export const fetchPostSuccess = createAction(FETCH_POST_SUCCESS);
export const fetchPostFailure = createAction(FETCH_POST_FAILURE);

const makeRequestToPlatformizedApi = async (
  postSlugOrId: string,
  {
    includeDraft,
    instance,
    httpClient,
    languageCode,
  }: MakeRequestToPlatformizedParams,
) => {
  const platformizedParams = {
    translationsName: 'main',
    languageCode,
  };

  const postPageRequest =
    includeDraft && instance
      ? httpClient.request(
          getPostPagePreview({
            draftPostId: decodeURI(postSlugOrId),
            ...platformizedParams,
          }),
          {
            signedInstance: instance,
          },
        )
      : httpClient.request(
          getPostPage({
            postId: decodeURI(postSlugOrId),
            ...platformizedParams,
          }),
        );

  return postPageRequest.then(({ data }) => {
    const postPage = data?.postPage;
    const post = postPage?.post;

    if (!post || !postPage || typeof post.slug === 'undefined') {
      throw new Error('Post not found');
    }

    return {
      ...normalizePostV3(post),
      tags: postPage.tags ?? [],
      excerpt: post?.customExcerpt ? post.excerpt : undefined,
      /*
          To maintain compatibility with the old post page, we need to return the slug in the slugs array.
          It is used in the post page to determine if the post is avialable by slug or not.
        */
      slugs: [post.slug, postSlugOrId],
    };
  });
};

const makeRequest = (
  postSlug: string,
  { includeDraft, request, instance }: MakeRequestParams,
) => {
  if (!postSlug) {
    throw new Error('Missing postSlug');
  }

  const fieldsets = `categories,owner,likes,content,subscriptions,tags,seo,translations,urls`;
  const requestUrl = urijs(
    `/_api/posts/${!includeDraft ? 'content/' : ''}${postSlug}`,
  ).query({
    fieldsets,
  });
  return request(requestUrl, { instance });
};

export const fetchPostFromPlatfomized = (
  postSlug: string,
  { includeDraft, instance }: PreviewPostParams = {},
) => {
  return (
    dispatch: (action: PostActionTypes) => void,
    getState: () => AppState,
    {
      httpClient,
      wixCodeApi,
      aggregatorRequest,
    }: {
      request: RequestClient;
      httpClient: HttpClient;
      wixCodeApi: WixCodeApi;
      aggregatorRequest: AggregatorRequest;
    },
  ) => {
    const unencodedPostSlug = encodeURISlug(postSlug);
    dispatch(fetchPostRequest({ postSlug: unencodedPostSlug }));
    const state = getState();

    const promise = makeRequestToPlatformizedApi(unencodedPostSlug, {
      httpClient,
      languageCode: getLanguageCode(state),
      includeDraft,
      instance,
    });

    return completeFetchPost(unencodedPostSlug, promise)(dispatch, getState, {
      httpClient,
      wixCodeApi,
      aggregatorRequest,
    });
  };
};

export const fetchPost = (
  postSlug: string,
  { includeDraft, instance }: PreviewPostParams = {},
) => {
  return (
    dispatch: (action: PostActionTypes) => void,
    getState: () => AppState,
    {
      request,
      httpClient,
      wixCodeApi,
      aggregatorRequest,
    }: {
      request: RequestClient;
      httpClient: HttpClient;
      wixCodeApi: WixCodeApi;
      aggregatorRequest: AggregatorRequest;
    },
  ) => {
    const promise = preFetchPost(postSlug, {
      includeDraft,
      instance,
    })(dispatch, getState, { request, httpClient });
    return completeFetchPost(postSlug, promise)(dispatch, getState, {
      httpClient,
      wixCodeApi,
      aggregatorRequest,
    });
  };
};

export const preFetchPost =
  (postSlug: string, { includeDraft, instance }: PreviewPostParams = {}) =>
  (
    dispatch: (action: PostActionTypes) => void,
    getState: () => AppState,
    { request, httpClient }: { request: RequestClient; httpClient: HttpClient },
  ) => {
    postSlug = encodeURISlug(postSlug);
    dispatch(fetchPostRequest({ postSlug }));

    return makeRequest(postSlug, {
      includeDraft,
      request,
      instance,
    });
  };

export const preFetchPostFromPlatformized =
  (postSlug: string, { includeDraft, instance }: PreviewPostParams = {}) =>
  (
    dispatch: (action: PostActionTypes) => void,
    getState: () => AppState,
    { httpClient }: { httpClient: HttpClient },
  ) => {
    const unencodedPostSlug = encodeURISlug(postSlug);
    dispatch(fetchPostRequest({ postSlug: unencodedPostSlug }));

    const state = getState();
    const languageCode = getLanguageCode(state);

    return makeRequestToPlatformizedApi(unencodedPostSlug, {
      languageCode,
      includeDraft,
      instance,
      httpClient,
    });
  };

export const completeFetchPost =
  (postSlug: string, preFetchResult: Promise<NormalizedPost>) =>
  async (
    dispatch: (action: PostActionTypes) => void,
    getState: () => AppState,
    {
      wixCodeApi,
      httpClient,
      aggregatorRequest,
    }: {
      wixCodeApi: WixCodeApi;
      httpClient: HttpClient;
      aggregatorRequest: AggregatorRequest;
    },
  ) => {
    postSlug = encodeURISlug(postSlug);

    try {
      let post;
      let capturedError;

      try {
        post = await preFetchResult;
      } catch (e) {
        capturedError =
          e && typeof e === 'object' && 'response' in e ? e.response : e;

        if (
          capturedError &&
          typeof capturedError === 'object' &&
          'status' in capturedError &&
          capturedError.status === 404 &&
          getEnvironment(wixCodeApi).isEditorSegment
        ) {
          const resp = await getDemoPosts({
            httpClient,
            getState,
            dispatch,
            wixCodeApi,
            aggregatorRequest,
            query: { slugs: [postSlug] },
          });
          post = resp?.posts?.[0];
          if (post) {
            capturedError = null;
          }
        }
      }

      if (capturedError) {
        throw capturedError;
      }

      const normalizedPost = normalizePost({
        state: getState(),
        post,
        blogCategoryIds: getCategoryIds(getState()),
      });

      dispatch(fetchPostSuccess({ post: normalizedPost, postSlug }));

      return normalizedPost;
    } catch (error) {
      dispatch(fetchPostFailure({ postSlug, error }));
      throw error;
    }
  };
