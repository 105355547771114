import {
  ROUTE_ARCHIVE,
  ROUTE_CATEGORIES,
  ROUTE_CATEGORY,
  ROUTE_HASHTAG,
  ROUTE_HOME,
  ROUTE_TAGS,
} from '../../feed-page/constants/routes';

/** Checks if the route uses frontend adapter to fetch data (translations, posts, tags, etc.) */
export const isFeedAdapterEnabled = (route: string) => {
  const routesUsingAdapter = [
    ROUTE_CATEGORY,
    ROUTE_CATEGORIES,
    ROUTE_HOME,
    ROUTE_TAGS,
    ROUTE_HASHTAG,
    ROUTE_ARCHIVE,
  ];

  return routesUsingAdapter.includes(route);
};
