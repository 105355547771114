import type { AppData } from '../../viewer.app';
import model from './model';

const selectors = {
  commentsSlot: '#slotComments',
};

export default model.createController(({ $w, appData: _appData }) => {
  const appData = _appData as AppData;

  const isResourceIdSupported: Boolean = $w(
    selectors.commentsSlot,
  ).slot.isMethodSupported('resourceId');

  return {
    pageReady: async () => {
      if (!isResourceIdSupported) {
        return;
      }

      await appData.subjects.postPageRenderModel.subscribe((data) => {
        if (isResourceIdSupported) {
          $w(selectors.commentsSlot).slot.resourceId = data.post.id;
        }
      });
    },
    exports: {},
  };
});
