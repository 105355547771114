import { getPricingPlansPublicAPI } from '@wix/pricing-plans-tpa-api';
import type {
  IWixAPI,
  PlatformControllerFlowAPI,
} from '@wix/yoshi-flow-editor';
import { likePost, unlikePost } from '@wix/ambassador-blog-v3-post/http';
import {
  BLOG_APP_ID,
  BLOG_POST_PAGE_SECTION_ID,
} from '@wix/communities-universal/dist/src/constants/appsConfig';
import { raise } from '@wix/communities-blog-client-common';
import { blogAppDefId } from '../common/constants/apps';
import { SANTA_MEMBERS_APP_ID } from '../common/constants/members-area';
import { REFERRAL_BLOG_POST_PAYWALL } from '../common/constants/paid-plans';
import { TPA_PAGE_ID_BLOG } from '../common/constants/tpa-pages';
import { getBlogFeedSectionUrl } from '../common/services/get-section-url';
import { NormalizedPost } from '../common/types';

export class TranslatedPostRedirectError extends Error {}
export class TranslatedPostNotFoundError extends Error {
  constructor() {
    super('Post language does not match current language', {
      cause: 404,
    });
  }
}

export class BlocksWidgetFacade {
  constructor(
    private readonly flowAPI: PlatformControllerFlowAPI,
    private readonly wixCodeApi: IWixAPI,
  ) {}

  async navigateToCategoryFeed(slug: string) {
    const url = await this.makeCategoryFeedUrl(slug);
    this.navigateToFeed(url);
  }

  async navigateToTagFeed(slug: string) {
    const url = await this.makeTagFeedUrl(slug);
    this.navigateToFeed(url);
  }

  /**
   * @example
   * ```ts
   * await facade.getUrlToFeedPage(); // -> https://user.wixsite.com/site-1/blog
   * ```
   */
  getUrlToFeedPage() {
    return getBlogFeedSectionUrl(this.wixCodeApi);
  }

  async likePost(postId: string) {
    return this.flowAPI.httpClient.request(likePost({ postId }));
  }

  async dislikePost(postId: string) {
    return this.flowAPI.httpClient.request(unlikePost({ postId }));
  }

  async navigateToPricingPage(post: NormalizedPost) {
    const api = await getPricingPlansPublicAPI(this.wixCodeApi);

    api.navigateToPricingPage({
      planIds: post.pricingPlanIds,
      checkout: {
        thankYouPage: {
          content: {
            title: 'Thanks for Subscribing',
            message:
              'A confirmation email is on its way to you.\nNow you can get back to your post!',
            cta: 'Keep Reading',
          },
          navigation: post.slug
            ? {
                type: 'section',
                options: {
                  sectionId: BLOG_POST_PAGE_SECTION_ID,
                  appDefinitionId: BLOG_APP_ID,
                  state: post.slug,
                },
              }
            : undefined,
        },
      },
      biOptions: {
        referralInfo: REFERRAL_BLOG_POST_PAYWALL,
        referralId: post.id,
      },
    });
  }

  async navigateToAuthorProfile(post: NormalizedPost) {
    const memberId =
      post.owner?.siteMemberId || raise('"Post.owner.siteMemberId" not found');
    const memberSlug = post.owner?.slug || raise('"Post.owner.slug" not found');

    this.flowAPI.controllerConfig.wixCodeApi.site
      .getPublicAPI(SANTA_MEMBERS_APP_ID)
      .then((api) => {
        api.navigateToMember({
          memberId,
          memberSlug,
        });
      });
  }

  /**
   * @throws {RedirectError} - if post language does not match current language after redirecting to the posts correct language
   * @throws {TranslatedPostNotFoundError} - if post language does not match current language and no translated post is found
   */
  assertPostIsLoadedInCorrectLanguage(post: NormalizedPost): void | never {
    const currentLanguage =
      this.wixCodeApi.window.multilingual.currentLanguage ||
      (this.wixCodeApi.site.language ?? 'en');

    if (post.language === currentLanguage) {
      return;
    }

    const translation = post.translations?.find(
      (t) => t.language === currentLanguage,
    );

    if (translation?.url) {
      const { base, path } = translation.url;
      if (base && path) {
        const { searchParams } = new URL(this.wixCodeApi.location.url);
        const url = `${base}${path}`;

        this.wixCodeApi.location.to?.(
          searchParams.size > 0 ? `${url}?${searchParams}` : url,
        );

        throw new TranslatedPostRedirectError();
      }
    } else {
      throw new TranslatedPostNotFoundError();
    }
  }

  private async makeCategoryFeedUrl(categorySlug: string) {
    const { url } =
      await this.flowAPI.controllerConfig.wixCodeApi.site.getSectionUrl({
        sectionId: TPA_PAGE_ID_BLOG,
        appDefinitionId: blogAppDefId,
      });
    if (url) {
      return `${url}/categories/${categorySlug}`;
    }
    return;
  }

  private async makeTagFeedUrl(tag: string) {
    const { url } =
      await this.flowAPI.controllerConfig.wixCodeApi.site.getSectionUrl({
        sectionId: TPA_PAGE_ID_BLOG,
        appDefinitionId: blogAppDefId,
      });
    if (url) {
      return `${url}/tags/${tag}`;
    }
    return;
  }

  private async navigateToFeed(url?: string) {
    if (!url) {
      return;
    }
    this.flowAPI.controllerConfig.wixCodeApi.location.to?.(url);
  }
}
